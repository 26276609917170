
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useRoute } from 'vue-router';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import ServicoIntegracao from '@/servicos/ServicoIntegracao';
import { IIntegracao } from '@/models/Entidades/Integracao';
import { ETipoIntegracao } from '@/models/Enumeradores/ETipoIntegracao';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOAdicionarIntegracao } from '@/models/DTO/Integracoes/IDTOAdicionarIntegracao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'IntegracoesIndex',
  components: {
    TituloPadrao,
    Icone,
    SelecionarSimNao,
    SelecionarData,
    RequisicaoModal,
  },
  props: {
  },
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();

    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const route = useRoute();

    const servicoIntegracao = new ServicoIntegracao();

    const state = reactive({
      integracaoEmVisualizacao: 0,
      integracoes: [] as IIntegracao[],
      codigosEmEdicao: [] as number[],
      novaIntegracao: {} as IDTOAdicionarIntegracao,
      apresentarNovaIntegracao: false,
      sincronizandoVinculos: false,
    });

    state.integracaoEmVisualizacao = 0;
    state.integracoes = [];
    state.codigosEmEdicao = [];

    onBeforeMount(async () => {
      telaBase.carregando = true;
      state.integracoes = await servicoIntegracao.obterIntegracoes();

      const codigoMensagem = String(route.query.msg);
      if (UtilitarioGeral.valorValido(codigoMensagem)) {
        const mensagemComunicacao = storeSistema.getters.obterMensagemComunicaco(codigoMensagem);
        if (mensagemComunicacao !== undefined && UtilitarioGeral.valorValido(mensagemComunicacao.mensagem)) {
          if (mensagemComunicacao.tipo === EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemSucesso(mensagemComunicacao.mensagem);
          } else {
            apresentarMensagemAlerta(mensagemComunicacao.mensagem);
          }
          storeSistema.mutations.removerMensagemComunicacao(codigoMensagem);
        }
      }
      telaBase.carregando = false;
    });

    function defineIntegracaoAlterada(codigoIntegracao: number) {
      if (!state.codigosEmEdicao.some((c) => c === codigoIntegracao)) {
        state.codigosEmEdicao.push(codigoIntegracao);
      }
    }

    function apresentarBotaoSalvar(codigoIntegracao: number): boolean {
      return state.codigosEmEdicao.some((c) => c === codigoIntegracao);
    }

    function adicionarNovaIntegracao(tipo: ETipoIntegracao) {
      state.novaIntegracao = {} as IDTOAdicionarIntegracao;
      state.novaIntegracao.tipo = tipo;
      state.novaIntegracao.sandbox = false;
      state.novaIntegracao.enviarCadastros = false;
      state.apresentarNovaIntegracao = true;
    }

    async function salvarNovaIntegracao() {
      apresentarBarraProgresso('Aguarde por favor... Estamos salvando as suas informações.');
      let retorno:IRetornoRequisicao = {} as IRetornoRequisicao;
      if (state.novaIntegracao.tipo === ETipoIntegracao.AnyMarket) {
        retorno = await servicoIntegracao.adicionarIntegracaoAnyMarket(state.novaIntegracao);
      } else if (state.novaIntegracao.tipo === ETipoIntegracao.WooCommerce) {
        retorno = await servicoIntegracao.adicionarIntegracaoWooCommerce(state.novaIntegracao);
      }
      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        state.apresentarNovaIntegracao = false;
        state.integracoes = await servicoIntegracao.obterIntegracoes();
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function salvar(integracao: IIntegracao) {
      apresentarBarraProgresso('Aguarde por favor... Estamos salvando as suas informações.');

      const retorno = await servicoIntegracao.alterar(integracao);

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        const indexIntegracao = state.codigosEmEdicao.findIndex((c) => c === integracao.codigo);
        if (indexIntegracao >= 0) {
          state.codigosEmEdicao.splice(indexIntegracao, 1);
        }
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function testarComunicacao(integracao: IIntegracao) {
      apresentarBarraProgresso('Aguarde por favor... Estamos testando a comunicação.');

      const retorno = await servicoIntegracao.testarComunicacacao(integracao.codigo);
      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        const indexIntegracao = state.codigosEmEdicao.findIndex((c) => c === integracao.codigo);
        if (indexIntegracao >= 0) {
          state.codigosEmEdicao.splice(indexIntegracao, 1);
        }
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function sincronizarVinculosProdutos(integracao: IIntegracao) {
      apresentarBarraProgresso('Aguarde por favor... Estamos sincronizando os produtos...');

      const retorno = await servicoIntegracao.sincronizarVinculosProdutos(integracao.codigo);
      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        const indexIntegracao = state.codigosEmEdicao.findIndex((c) => c === integracao.codigo);
        if (indexIntegracao >= 0) {
          state.codigosEmEdicao.splice(indexIntegracao, 1);
        }
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    return {
      telaBase,
      modalBase,
      state,
      props,
      ETipoIntegracao,
      servicoIntegracao,
      UtilitarioGeral,
      defineIntegracaoAlterada,
      apresentarBotaoSalvar,
      adicionarNovaIntegracao,
      salvar,
      salvarNovaIntegracao,
      testarComunicacao,
      sincronizarVinculosProdutos,
    };
  },
});
