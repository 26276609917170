import ApiSistema from '@/core/conectores/ApiSistema';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOAdicionarIntegracao } from '@/models/DTO/Integracoes/IDTOAdicionarIntegracao';
import { IIntegracao } from '@/models/Entidades/Integracao';
import { ETipoIntegracao } from '@/models/Enumeradores/ETipoIntegracao';

/**
 * Serviço de Integrações
 * Fornece todas regras de negócios e lógicas relacionado as integrações.
 */

class ServicoIntegracao {
  private endPoint = 'integracoes';

  private apiSistema = new ApiSistema();

  public async obterIntegracoes(): Promise<IIntegracao[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}`);
    return result.data;
  }

  public async adicionarIntegracaoMercadoLivre(codigoAutorizacao: string): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/mercado-livre/${codigoAutorizacao}`);
    return result.data;
  }

  public async adicionarIntegracaoAnyMarket(objeto: IDTOAdicionarIntegracao): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/any-market`, objeto);
    return result.data;
  }

  public async adicionarIntegracaoWooCommerce(objeto: IDTOAdicionarIntegracao): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/woocommerce`, objeto);
    return result.data;
  }

  public async sincronizarVinculosProdutos(codigoIntegracao: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/${codigoIntegracao}/sincronizar/produtos`);
    return result.data;
  }

  public async testarComunicacacao(codigoIntegracao: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/${codigoIntegracao}/testar-comunicacao`);
    return result.data;
  }

  public async alterar(integracao: IIntegracao): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.put(`${this.endPoint}/${integracao.codigo}`, integracao);
    return result.data;
  }

  public verificaIntegracaoMarketPlace(tipo: ETipoIntegracao): boolean {
    if (tipo === ETipoIntegracao.MercadoLivre || tipo === ETipoIntegracao.Shopee || tipo === ETipoIntegracao.Amazon) {
      return true;
    }
    return false;
  }

  public obtemLogoIntegracao(tipo: ETipoIntegracao): string {
    let logo = '';
    switch (tipo) {
      case ETipoIntegracao.AnyMarket:
        logo = '/static/img/integracoes/anymarket.png';
        break;

      case ETipoIntegracao.MercadoLivre:
        logo = '/static/img/integracoes/mercado-livre.png';
        break;

      case ETipoIntegracao.Shopee:
        logo = '/static/img/integracoes/shopee.png';
        break;

      case ETipoIntegracao.Amazon:
        logo = '/static/img/integracoes/amazon.png';
        break;
        case ETipoIntegracao.WooCommerce:
        logo = '/static/img/integracoes/woocommerce.png';
        break;

      default:
        break;
    }

    return logo;
  }

  public obtemNomeIntegracao(tipo: ETipoIntegracao): string {
    let nome = '';
    switch (tipo) {
      case ETipoIntegracao.AnyMarket:
        nome = 'Any Market';
        break;

      case ETipoIntegracao.MercadoLivre:
        nome = 'Mercado Livre';
        break;

      case ETipoIntegracao.Shopee:
        nome = 'Shopee';
        break;

      case ETipoIntegracao.Amazon:
        nome = 'Amazon';
        break;

      default:
        break;
    }

    return nome;
  }
}
export default ServicoIntegracao;
